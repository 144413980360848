import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "590px",
  boxЫhadow: "-3px 4px 15px rgba(0, 0, 0, 0.2)",
  borderRadius: "20px",
};

const center = {
  lat: 25.323598861694336,
  lng: 55.41218185424805,
};

const GoogleMapComponent = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCexfyD8GPXOFlcOD9Zy_tHLaUTDscu_nY",
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMapComponent;
