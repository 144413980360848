import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultLanguage, supportedLanguages } from "../config/i18n";

i18n
    .use(HttpBackend) // Подключение к бэкенду для загрузки файлов переводов
    .use(LanguageDetector) // Автоматическое определение языка пользователя
    .use(initReactI18next) // Интеграция с React
    .init({
        fallbackLng: defaultLanguage, // Язык по умолчанию
        debug: true, // Логи для отладки
        supportedLngs: supportedLanguages.map((lang) => lang.code), // Поддерживаемые языки
        backend: {
            loadPath: "../locales/{{lng}}/{{ns}}.json" // Путь к файлам переводов
        },
        interpolation: {
            escapeValue: false // Отключение экранирования (не нужно для React)
        },
    });

export default i18n;
