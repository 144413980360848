import styled from "styled-components";

export const BenefitsSectionContainer = styled.div`
  max-width: 1440px;
  padding: 0 25px;
  margin: 100px auto 0;
`;

export const CallbackSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const StepContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const StepRight = styled.div`
  margin-right: 20px;
`;

export const StepLeft = styled.div`
  max-width: 315px;
`;

export const StepIcon = styled.img``;

export const StepTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const StepDesc = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
