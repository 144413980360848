import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import successIcon from "../../images/success.svg";
import {
  CallbackSuccessModalDesc,
  CallbackSuccessModalIcon,
  CallbackSuccessModalTitle,
} from "./CallbackElements";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "24px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
};

const CallbackSuccessModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CallbackSuccessModalIcon src={successIcon} alt="success icon" />
        <CallbackSuccessModalTitle>{t("callbackSuccessModal.callbackSuccessModalTitle")}</CallbackSuccessModalTitle>
        <CallbackSuccessModalDesc>
          {t("callbackSuccessModal.callbackSuccessModalDesc")}
        </CallbackSuccessModalDesc>
        <Button
          style={{
            color: "white",
            backgroundColor: "#4cbcd7",
            borderRadius: 50,
            height: 52,
          }}
          fullWidth
          type="submit"
          variant="contained"
          onClick={props.handleClose}
        >
          {t("callbackSuccessModal.button")}
        </Button>
      </Box>
    </Modal>
  );
};

export default CallbackSuccessModal;
