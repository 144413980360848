import React from "react";
import styled from "styled-components";
import { SectionTitle } from "../../components/main";

export const TermsConditionsContainer = styled.div`
  width: 100%;
`;

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const TermsConditionsEN = () => {
  return (
    <TermsConditionsContainer>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        GENERAL TERMS AND CONDITIONS OF CAR RENTAL AGREEMENT
      </SectionTitle>
      <TextWrapper>
        <p>
          These General Terms and Conditions form part of the Vehicle Rental Agreement between the Customer and AMAIS. The Terms and Conditions supplement the provisions of the Agreement.
        </p>

        <h3>1. Definitions</h3>
        <p>1.1 In these General Terms and Conditions, the following words and expressions have the following meanings:</p>
        <ul>
          <li><strong>Agreement</strong> means the agreement between the Customer and AMAIS, including these Terms and Conditions and the table on the AMAIS website, as amended.</li>
          <li><strong>Table</strong> means the table with car rental rates on the AMAIS website <a href="http://amaisrentacar.com">amaisrentacar.com</a>.</li>
          <li><strong>Additional Drivers</strong> means any additional drivers appointed by the Customer in the Agreement.</li>
          <li><strong>Customer</strong> means the client who has entered into an Agreement with AMAIS for the rental of a vehicle.</li>
          <li><strong>Driving License</strong> means a valid driving license issued by the relevant authorities, entitling the driver to drive a vehicle.</li>
          <li><strong>Rental Period</strong> means the rental period specified in the Agreement and the expected/confirmed date of return of the vehicle. Any extension of the Rental Period shall be deemed to be a new Rental Period.</li>
          <li><strong>Per Day</strong> for rental purposes shall mean twenty-four (24) hours.</li>
          <li><strong>Daily Rate</strong> shall mean the daily rental rate for the vehicle as stated in the Agreement.</li>
          <li><strong>Permitted Mileage</strong> shall mean the daily permitted mileage as stated in the Agreement.</li>
          <li><strong>Restricted Activities/Use</strong> shall include driving under the influence of alcohol, racing, off-road driving, driving through standing water, towing, etc.</li>
          <li><strong>Conditions</strong> shall mean these Standard Terms of the Agreement.</li>
          <li><strong>UAE</strong> shall mean the United Arab Emirates.</li>
        </ul>

        <p>A valid driving license shall meet one of the following conditions:</p>
        <p>1. For UAE nationals - a driving license issued in the UAE;</p>
        <p>2. For UAE residents - the license must be at least three years old or confirmed by an additional license from another country;</p>
        <p>3. For Chinese nationals, the license must be accompanied by a notarized translation into English;</p>
        <p>4. A driving license issued in the following countries:</p>
        <p>(i) North America: Canada and the United States;</p>
        <p>(ii) East Asia and the Pacific: Australia, China, Hong Kong, Japan, New Zealand, Singapore and South Korea;</p>
        <p>(iii) Europe: Albania, Austria, Belgium, Bulgaria, Cyprus, Denmark, Finland, Estonia, France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Montenegro, the Netherlands, Norway, Poland, Portugal, Romania, Serbia, Slovakia, Spain, Sweden, Switzerland, Turkey and the United Kingdom;</p>
        <p>(iv) GCC: Bahrain, Kuwait, Oman, Saudi Arabia and Qatar;</p>
        <p>(v) Africa: South Africa;</p>
        <p>1. International drivers may only drive the vehicle with an International Driving Permit and an original driving license from their home country if they are not UAE residents.</p>
        <p>In addition, the driving license must be accompanied by a valid passport or a copy of Emirates ID for UAE nationals and residents.</p>
        <p><strong>Vehicle</strong> means the AMAIS vehicle hired by the Customer in accordance with the terms of the Agreement.</p>

        <p>1.2 Unless the context otherwise requires:</p>
        <p>(a) the singular includes the plural and vice versa;</p>
        <p>(b) words in the masculine gender include the feminine;</p>
        <p>(c) words referring to individuals include companies, organizations, associations or groups of individuals.</p>
        <p>1.3 The word “may” shall be construed as a permission and the word “shall” shall be construed as an obligation.</p>
        <p>1.4 A reference to a document means a document in paper or electronic form.</p>
        <p>1.5 Unless the context otherwise requires, the singular includes the plural and the plural includes the singular.</p>
        <p>1.6 Unless the context otherwise requires, a reference to one gender includes a reference to the other gender.</p>
        <p>1.7 An obligation not to do something includes an obligation not to allow others to do it.</p>
        <p>1.8 All dates and periods of time are determined by reference to the Gregorian calendar.</p>
        <p>1.9 Unless the context otherwise requires, “Emirati Dirham” or “AED” means the currency of the UAE.</p>
        <p>1.10 The words “include”, “includes” and “including” shall be construed as meaning “without limitation”.</p>

        <h3>2. Right to rent, required documents and table</h3>
        <p>2.1 The Customer represents and warrants that he/she (and additional drivers) has a Valid Driver's License and will maintain it during the Rental Period.</p>
        <p>2.2 The Customer undertakes to notify AMAIS of the expiration or cancellation of the driver's license (his/hers and additional drivers) and to stop using the car.</p>
        <p>2.3 The Customer acknowledges that he/she has read and will comply with the schedule on the AMAIS website (amaisrentacar.com), which is part of the contract.</p>
        <p>2.4 An extension not agreed with AMAIS is considered a new rental period for the car.</p>
        <p>NOTE: Rates may be changed or increased by AMAIS at its sole discretion.</p>

        <h3>3. Delivery Policy</h3>
        <p>3.1 The date and time of delivery of the vehicle must be agreed between the Customer and AMAIS.</p>
        <p>3.2 The vehicle may be delivered outside normal working days and hours for an additional fee. The Customer must agree with the AMAIS representative on the appropriate fee.</p>
        <p>3.3 Upon acceptance of the vehicle, the Customer agrees that the vehicle is in the condition stated in the Agreement and must maintain this condition until the vehicle is returned.</p>

        <h3>4. Rental Period, Return Policy and Extensions</h3>
        <p>4.1 Daily charges for the vehicle are calculated on a 24-hour basis.</p>
        <p>4.2 The acceptable grace period is 2 hours on the return date. For delays exceeding two hours, the Customer will pay a penalty of AED 1,000.</p>
        <p>4.3 In the event of a reduction of the Rental Period by one day or more, the agreed daily charge will be recalculated and the remainder will be refunded to the Customer less an early termination fee of AED 500.</p>
        <p>4.4 Returns outside normal business hours or at locations other than agreed may be subject to additional charges. The Customer must confirm such charges with an AMAIS representative in advance.</p>
        <p>4.5 The Customer must make a written request for an extension of the Agreement before its expiration. The request is subject to acceptance or refusal by AMAIS.</p>
        <p>4.6 AMAIS may require the Customer to replace the vehicle during business hours on a business day for servicing or other purposes.</p>
        <p>4.7 AMAIS reserves the right to recall the vehicle at any time if the Customer has breached the terms of the Agreement.</p>
        <p>4.8 If the Customer fails to return the vehicle on the agreed date or fails to pay the charges, AMAIS may:</p>
        <p>(a) report the vehicle to the police as stolen;</p>
        <p>(b) take legal action to recover the damages;</p>
        <p>(c) return the vehicle without notifying the Customer;</p>
        <p>4.9 Inform AMAIS of any traffic fines or warnings during the rental period.</p>
        <p>4.10 Provide the exact location of the vehicle upon request by AMAIS.</p>
        <p>4.11 Allow an AMAIS representative to inspect the vehicle upon request.</p>

        <h3>5. Booking Requirements</h3>
        <p>5.1 The Customer must pay a deposit before picking up the car. The deposit will be held by AMAIS for 14 days after the end of the rental to account for penalties and additional fees.</p>
        <p>5.2 If the booking is cancelled 72 hours or more before the rental start date, the deposit will be refunded to the Customer.</p>
        <p>5.3 If the booking is cancelled less than 72 hours before the rental start date, the deposit will be refunded minus AED 500.</p>
        <p>5.4 If the booking is postponed or shortened 72 hours or less before the rental start date, the Customer will pay an additional fee of AED 500.</p>
        <p>5.5 The Customer must provide original documents upon delivery of the car. Failure to comply will result in a penalty of AED 500.</p>

        <h3>6. Fees and Payments</h3>
        <p>6.1 All fees are exclusive of VAT and other taxes, which are paid by the Customer.</p>
        <p>6.2 AMAIS will charge a fee of AED 150 for each additional driver. The Customer and additional drivers remain jointly liable under the Agreement.</p>
        <p>6.3 Any additional mileage will be charged as per the Agreement.</p>
        <p>6.4 A fee of AED 5 will be charged for each trip through Salik.</p>
        <p>6.5 All fines during the rental are paid by the Customer. AMAIS may debit the Customer's deposit.</p>
        <p>6.6 The Customer authorizes AMAIS to debit the deposit for all payments under the Agreement.</p>

      </TextWrapper>
    </TermsConditionsContainer >
  );
};

export default TermsConditionsEN;
