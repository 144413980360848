import React, { useEffect, useState } from "react";
import { SectionTitle } from "../main";
import {
  CarFleetContainer,
  CarouselActionWrapper,
  CustomButtonBack,
  CustomButtonNext,
  DotGroupWrapper,
} from "./CarFleetSectionElements";
import { IconButton } from "@mui/material";
import { autoParkData } from "../../data/autoparkData";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  CarouselProvider,
  DotGroup,
  Slide,
  Slider as PureSlider,
} from "pure-react-carousel";

import arrowLeftIcon from "../../images/arrow-left-circle.svg";
import arrowRightIcon from "../../images/arrow-right-circle.svg";
import ImageCaruselModal from "../Modals/ImageCaruselModal/ImageCaruselModal";
import CarCard from "./CarCard";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledPureSlider = styled(PureSlider)`
  .carousel__slide-focus-ring {
    outline: none !important;
  }
`;

const CarFleetSection = () => {
  const { t } = useTranslation();
  const [visibleSlides, setVisibleSlides] = useState(3);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const handleOpen = (images) => {
    setOpen(true);
    setImages(images);
  };

  const handleClose = () => setOpen(false);

  const updateSlidesToShow = () => {
    if (window.innerWidth < 768) {
      setVisibleSlides(1);
    } else if (window.innerWidth < 1320) {
      setVisibleSlides(2);
    } else {
      setVisibleSlides(3);
    }
  };

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);

    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  return (
    <>
      <ImageCaruselModal
        images={images}
        open={open}
        handleClose={handleClose}
      />
      <CarFleetContainer id="autoCatalog">
        <SectionTitle>{t("carFleetSection.sectionTitle")}</SectionTitle>
        <CarouselProvider
          style={{ outline: "none" }}
          visibleSlides={visibleSlides}
          totalSlides={autoParkData.length}
          step={1}
          naturalSlideWidth={400}
          naturalSlideHeight={700}
          infinite
        >
          <CarouselActionWrapper>
            <CustomButtonBack>
              <IconButton color="primary" size="large">
                <img src={arrowLeftIcon} alt="arrowLeft" />
              </IconButton>
            </CustomButtonBack>
            <DotGroup
              renderDots={({ currentSlide }) => (
                <DotGroupWrapper>
                  {Array.from({ length: autoParkData.length }, (_, index) => (
                    <div
                      style={{
                        backgroundColor:
                          currentSlide === index
                            ? "rgba(76, 188, 215, 0.5)"
                            : "#4cbcd7",
                        width: "14px",
                        height: "14px",
                        borderRadius: "100%",
                        margin: "0 10",
                      }}
                      slide={index}
                      isActive={currentSlide === index}
                      key={`dot-${index}`}
                    />
                  ))}
                </DotGroupWrapper>
              )}
            />
            <CustomButtonNext>
              <IconButton color="info" size="large">
                <img src={arrowRightIcon} alt="arrowRight" />
              </IconButton>
            </CustomButtonNext>
          </CarouselActionWrapper>
          <StyledPureSlider
            style={{ outline: "none", padding: "20px", minHeight: 760 }}
          >
            {autoParkData.map((item, index) => (
              <Slide index={index} style={{ outline: "none" }}>
                <CarCard handleOpen={handleOpen} item={item} />
              </Slide>
            ))}
          </StyledPureSlider>
        </CarouselProvider>
      </CarFleetContainer>
    </>
  );
};

export default CarFleetSection;
