import styled from "styled-components";

export const RentalThreeStepsContainer = styled.div`
  max-width: 1440px;
  padding: 0 25px;
  margin: 100px auto 0;
`;

export const CallbackSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

export const RentalThreeStepsIcon = styled.img``;

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StepContainer = styled.div`
  display: flex;
`;

export const StepDivider = styled.div`
  width: 76px;
  height: 2px;
  background-color: #4cbcd7;
  margin-top: 17px;
  margin-bottom: 20px;
`;

export const StepRight = styled.div`
  margin-right: 20px;
`;

export const StepLeft = styled.div`
  margin-top: 20px;
  max-width: 200px;
`;

export const StepIcon = styled.img``;

export const StepTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const StepDesc = styled.div`
  font-size: 18px;
  font-weight: 400;
`;
