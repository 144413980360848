import "./App.css";
import Home from "./pages";
import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import NavBar from "./components/NavBar";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import { SecondPagesWrapper } from "./components/main";
import TermsConditionsPage from "./pages/TermsConditions";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import FooterSection from "./components/FooterSection";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4cbcd7",
    },
  },
});

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <NavBar toggle={toggle} />
        <Switch>
          <Route path="/" component={Home} exact />
          <SecondPagesWrapper>
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route
              path="/terms-and-conditions"
              component={TermsConditionsPage}
            />
          </SecondPagesWrapper>
        </Switch>
        <FooterSection />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
