import React from "react";
import { SectionTitle } from "../main";
import {
  ContactsContainer,
  Wrapper,
  Info,
  Title,
  Section,
  ContactsIcon,
  ContactsLink,
  ContactsWrapperItem,
} from "./ContactsElements";

import phoneIcon from "../../images/phone.svg";
import locationIcon from "../../images/location.svg";
import emailIcon from "../../images/email.svg";

import whatsappIcon from "../../images/whatsapp.svg";
import telegramIcon from "../../images/telegram.svg";
import facebookIcon from "../../images/facebook.svg";
import instagrammIcon from "../../images/instagramm.svg";
import workingTimeIcon from "../../images/workingTime.svg";

import { mainData } from "../../data/mainData";

import GoogleMapComponent from "./GoogleMaps";
import { useTranslation } from "react-i18next";

const ContactsSection = () => {
  const { t } = useTranslation();

  return (
    <ContactsContainer>
      <Section id="contacts">
        <SectionTitle>{t("contactsSection.sectionTitle")}</SectionTitle>
        <Wrapper>
          <Info>
            <ContactsWrapperItem>
              <Title>
                <ContactsIcon src={phoneIcon} />
                {t("contactsSection.phone")}:
              </Title>
              <ContactsLink href={`tel:${mainData.phoneNumber}`}>
                {mainData.phoneNumber}
              </ContactsLink>
            </ContactsWrapperItem>
            <ContactsWrapperItem>
              <Title>{t("contactsSection.messengers")}:</Title>
              <a target="_blank" href={mainData.telegramm} rel="noopener noreferrer">
                <ContactsIcon src={telegramIcon} />
              </a>
              <a target="_blank" href={mainData.whatsapp} rel="noopener noreferrer">
                <ContactsIcon src={whatsappIcon} />
              </a>
            </ContactsWrapperItem>
            <ContactsWrapperItem>
              <Title>{t("contactsSection.socialMedia")}:</Title>
              <ContactsLink href={mainData.facebook} target="_blank">
                <ContactsIcon src={facebookIcon} />
              </ContactsLink>
              <ContactsLink href={mainData.instagramm} target="_blank">
                <ContactsIcon src={instagrammIcon} />
              </ContactsLink>
            </ContactsWrapperItem>
            <ContactsWrapperItem>
              <Title>
                <ContactsLink href={`mailto:${mainData.email}`}>
                  <ContactsIcon src={emailIcon} />
                </ContactsLink>
                E-mail:
              </Title>
              <ContactsLink href={`mailto:${mainData.email}`}>
                {mainData.email}
              </ContactsLink>
            </ContactsWrapperItem>
            <ContactsWrapperItem>
              <Title>
                <ContactsIcon src={locationIcon} />
                {t("contactsSection.address")}:
              </Title>
              <ContactsLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/W8+SHIPPING/@25.3220205,55.4149702,18.97z/data=!4m6!3m5!1s0x3e5f59e47e0c5ae3:0x4a5bc9890647bf7e!8m2!3d25.3222281!4d55.4149867!16s%2Fg%2F11f776m87l?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
              >
                Industrial Area 5, 20th Street
              </ContactsLink>
            </ContactsWrapperItem>
            <ContactsWrapperItem>
              <Title>
                <ContactsIcon src={workingTimeIcon} />
                {t("contactsSection.workingHours")}:
              </Title>
              <ContactsLink>10:00 am — 10:00 pm</ContactsLink>
            </ContactsWrapperItem>
          </Info>
          <GoogleMapComponent />
        </Wrapper>
      </Section>
    </ContactsContainer>
  );
};

export default ContactsSection;
