import React from "react";
import { SectionTitle } from "../../components/main";
import styled from "styled-components";
import { mainData } from "../../data/mainData";

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const PrivacyPolicyEN = () => {
  return (
    <>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        Privacy Policy (AMAIS/United Arab Emirates)
      </SectionTitle>
      <TextWrapper>
        <p>
          Welcome to the AMAIS Privacy Policy. This Privacy Policy describes how AMAIS collects and processes your personal data when you visit our website to register for an account and use our services. Please read this privacy policy for detailed information on how we process data and also review the car rental agreement and its terms.
        </p>

        <h3>CONTROLLER</h3>
        <p>AMAIS Car Rental is the controller and responsible for your personal data.</p>

        <h3>DATA WE COLLECT ABOUT YOU</h3>
        <p>According to Federal Decree-Law No. 45 of 2021 on the Protection of Personal Data (PDPL), personal data is any data relating to an identified or identifiable natural person:</p>
        <ul style={{ marginBottom: 10 }}>
          <li>Name.</li>
          <li>Identification number.</li>
          <li>Electronic identifier.</li>
          <li>Geographical location.</li>
          <li>Physical, physiological, economic, cultural or social characteristics.</li>
          <li>Sensitive data such as family or ethnic origin, political or philosophical views, religious beliefs, criminal history, biometric data and health data.</li>
        </ul>

        <h3>HOW WE USE YOUR PERSONAL DATA</h3>
        <p>AMAIS will use and share your data in the following cases:</p>
        <ul style={{ marginBottom: 10 }}>
          <li>To fulfill a contract or comply with a legal obligation.</li>
          <li>
            To register you as a new customer:
            <ul>
              <li>To deliver a rental car.</li>
              <li>To manage your relationship with us.</li>
              <li>So that you can leave a review or complete a survey.</li>
            </ul>
          </li>
          <li>To improve our website.</li>
          <li>We may share your data with business partners to offer services or promotions that may be of interest to you.</li>
        </ul>

        <h3>LINKS TO OTHER SITES</h3>
        <p>
          Our website may contain links to other websites whose information practices may differ from ours. We encourage you to review their privacy policies as we have no control over the information transmitted or collected by these websites.
        </p>

        <h3>CROSS-BORDER TRANSFERS OF PERSONAL DATA</h3>
        <p>
          PDPL permits data transfers outside the UAE to countries with an "adequate level of protection". This includes countries with data protection laws or that have signed relevant agreements. For countries without an adequate level of protection, the law offers the following transfer options:
        </p>
        <ul style={{ marginBottom: 10 }}>
          <li>By contract or agreement that complies with the requirements of the law.</li>
          <li>
            With the explicit consent of the data subject, unless this is contrary to the public interest and security of the UAE.
          </li>
          <li>Necessary for the performance of a contract between the controller and the data subject, or with a third party in the interests of the data subject.</li>
          <li>Necessary for the exercise of an obligation or to defend rights in judicial proceedings.</li>
          <li>Necessary for international judicial cooperation.</li>
          <li>Necessary to protect the public interest.</li>
        </ul>

        <h3>COOKIES</h3>
        <p>
          You can choose whether to accept or reject cookies. Most browsers automatically accept cookies. If you disable or refuse them, some parts of the site may become inaccessible or not function properly.
        </p>

        <h3>YOUR LEGAL RIGHTS</h3>
        <p>PDPL provides you with several rights in relation to your data:</p>
        <ul style={{ marginBottom: 10 }}>
          <li>Receive or have access to your data.</li>
          <li>Request the transfer of your data.</li>
          <li>Request the correction or deletion of your data.</li>
          <li>Request the restriction of the processing of your data in certain cases.</li>
          <li>Request that the processing of your data cease in certain cases.</li>
          <li>Object to data processing.</li>
          <li>
            Object to decisions based on automated processing that produce legal effects or significantly affect you.
          </li>
          <li>Withdraw consent to data processing at any time.</li>
          <li>File a complaint with the UAE Data Protection Authority.</li>
        </ul>
        <p>
          If you wish to exercise these rights, please contact our customer service team using the contact details provided.
        </p>

        <h3>DATA SECURITY</h3>
        <p>We value your trust and take steps to prevent the loss, use or disclosure of your data.</p>

        <h3>CHILDREN'S PRIVACY</h3>
        <p>This site is not intended for children and we do not knowingly collect data from children. AMAIS services are not intended for individuals under 21 years of age.</p>

        <h3>CHANGES TO PRIVACY POLICY</h3>
        <p>
          Please check this Privacy Policy periodically to stay informed of any changes. Please notify us if your data changes during our relationship.
        </p>

        <h3>ADDITIONAL INFORMATION</h3>
        <p>If you have any questions about how we process your data, please contact us at{" "}
          <a style={{ color: "black" }} href={`mailto:${mainData.email}`}>
            {mainData.email}
          </a>
        </p>

        <p>Need expert advice? We will be happy to answer all your questions. Fill out the contact form.</p>

      </TextWrapper>
    </>
  );
};

export default PrivacyPolicyEN;
