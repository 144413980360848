import React from "react";
import { SectionTitle } from "../../components/main";
import styled from "styled-components";
import { mainData } from "../../data/mainData";

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const PrivacyPolicyRU = () => {
  return (
    <>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        Политика конфиденциальности (AMAIS/Объединенные Арабские Эмираты)
      </SectionTitle>
      <TextWrapper>
        <p>
          Добро пожаловать в политику конфиденциальности AMAIS. В данной политике описывается, как AMAIS собирает и обрабатывает ваши персональные данные, когда вы посещаете наш веб-сайт для регистрации учетной записи и использования наших услуг. Пожалуйста, внимательно прочтите данную политику, а также ознакомьтесь с условиями договора аренды автомобиля.
        </p>

        <h3>КОНТРОЛЕР</h3>
        <p>AMAIS Car Rental является контролером и ответственным за ваши персональные данные.</p>

        <h3>ДАННЫЕ, КОТОРЫЕ МЫ СОБИРАЕМ О ВАС</h3>
        <p>В соответствии с Федеральным законом № 45 от 2021 года о защите персональных данных (PDPL), персональные данные — это любые данные, относящиеся к идентифицированному или идентифицируемому физическому лицу:</p>
        <ul style={{ marginBottom: 10 }}>
          <li>Имя.</li>
          <li>Идентификационный номер.</li>
          <li>Электронный идентификатор.</li>
          <li>Географическое местоположение.</li>
          <li>Физические, физиологические, экономические, культурные или социальные характеристики.</li>
          <li>Чувствительные данные, такие как семейное или этническое происхождение, политические или философские взгляды, религиозные убеждения, уголовная история, биометрические данные и данные о здоровье.</li>
        </ul>

        <h3>КАК МЫ ИСПОЛЬЗУЕМ ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ</h3>
        <p>AMAIS будет использовать и делиться вашими данными в следующих случаях:</p>
        <ul style={{ marginBottom: 10 }}>
          <li>Для выполнения контракта или соблюдения юридического обязательства.</li>
          <li>
            Для регистрации вас в качестве нового клиента:
            <ul>
              <li>Для доставки арендованного автомобиля.</li>
              <li>Для управления вашими взаимоотношениями с нами.</li>
              <li>Для того, чтобы вы могли оставить отзыв или заполнить опрос.</li>
            </ul>
          </li>
          <li>Для улучшения нашего веб-сайта.</li>
          <li>Мы можем делиться вашими данными с бизнес-партнерами для предложения услуг или акций, которые могут вас заинтересовать.</li>
        </ul>

        <h3>ССЫЛКИ НА ДРУГИЕ САЙТЫ</h3>
        <p>
          Наш веб-сайт может содержать ссылки на другие сайты, чьи информационные практики могут отличаться от наших. Мы рекомендуем вам ознакомиться с их политиками конфиденциальности, так как мы не контролируем информацию, передаваемую или собираемую этими сайтами.
        </p>

        <h3>ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
        <p>
          PDPL разрешает передачу данных за пределы ОАЭ в страны с «адекватным уровнем защиты». Это включает страны с законами о защите данных или подписавшие соответствующие соглашения. Для стран без адекватного уровня защиты закон предлагает следующие варианты передачи:
        </p>
        <ul style={{ marginBottom: 10 }}>
          <li>По контракту или соглашению, соответствующему требованиям закона.</li>
          <li>
            С явного согласия субъекта данных, если это не противоречит общественным интересам и безопасности ОАЭ.
          </li>
          <li>Необходимо для выполнения контракта между контролером и субъектом данных или третьей стороной в интересах субъекта данных.</li>
          <li>Необходимо для выполнения обязательств или защиты прав в судебных процессах.</li>
          <li>Необходимо для международного судебного сотрудничества.</li>
          <li>Необходимо для защиты общественных интересов.</li>
        </ul>

        <h3>COOKIES</h3>
        <p>
          Вы можете выбрать, принимать или отклонять файлы cookie. Большинство браузеров автоматически принимают cookies. Если вы отключите или откажетесь от них, некоторые части сайта могут стать недоступными или не функционировать должным образом.
        </p>

        <h3>ВАШИ ПРАВА</h3>
        <p>PDPL предоставляет вам несколько прав в отношении ваших данных:</p>
        <ul style={{ marginBottom: 10 }}>
          <li>Получить или получить доступ к вашим данным.</li>
          <li>Запросить передачу ваших данных.</li>
          <li>Запросить исправление или удаление ваших данных.</li>
          <li>Запросить ограничение обработки ваших данных в определенных случаях.</li>
          <li>Запросить прекращение обработки ваших данных в определенных случаях.</li>
          <li>Возразить против обработки данных.</li>
          <li>
            Возразить против решений на основе автоматизированной обработки, которые оказывают юридическое воздействие или существенно влияют на вас.
          </li>
          <li>Отозвать согласие на обработку данных в любое время.</li>
          <li>Подать жалобу в Управление по защите данных ОАЭ.</li>
        </ul>
        <p>
          Если вы хотите воспользоваться этими правами, пожалуйста, свяжитесь с нашей службой поддержки, используя указанные контактные данные.
        </p>

        <h3>БЕЗОПАСНОСТЬ ДАННЫХ</h3>
        <p>Мы ценим ваше доверие и принимаем меры, чтобы предотвратить потерю, использование или разглашение ваших данных.</p>

        <h3>КОНФИДЕНЦИАЛЬНОСТЬ ДЕТЕЙ</h3>
        <p>Этот сайт не предназначен для детей, и мы сознательно не собираем данные от детей. Услуги AMAIS не предназначены для лиц младше 21 года.</p>

        <h3>ИЗМЕНЕНИЯ В ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ</h3>
        <p>
          Пожалуйста, периодически проверяйте эту политику конфиденциальности, чтобы быть в курсе изменений. Пожалуйста, сообщите нам, если ваши данные изменятся во время наших взаимоотношений.
        </p>

        <h3>ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</h3>
        <p>Если у вас есть вопросы о том, как мы обрабатываем ваши данные, пожалуйста, свяжитесь с нами по электронной почте{" "}
          <a style={{ color: "black" }} href={`mailto:${mainData.email}`}>
            {mainData.email}
          </a>
        </p>

        <p>Нужен экспертный совет? Мы будем рады ответить на все ваши вопросы. Заполните контактную форму.</p>

      </TextWrapper>
    </>
  );
};

export default PrivacyPolicyRU;
