import React from "react";
import { SectionTitle } from "../main";
import {
  RentalThreeStepsContainer,
  StepContainer,
  StepDesc,
  StepDivider,
  StepIcon,
  StepLeft,
  StepRight,
  StepsContainer,
  StepTitle,
} from "./RentalThreeStepsElements";

import step1Icon from "../../images/step1.svg";
import step2Icon from "../../images/step2.svg";
import step3Icon from "../../images/step3.svg";
import { useTranslation } from "react-i18next";

const RentalThreeStepsSection = () => {
  const icons = [step1Icon, step2Icon, step3Icon];
  const { t } = useTranslation();
  const stepData = t("rentalThreeStepsSection.stepData", { returnObjects: true }) ?? [];

  const stepDataWithIcons = stepData.map((item, index) => ({
    ...item,
    icon: icons[index]
  }));

  return (
    <RentalThreeStepsContainer id="rentStep">
      <SectionTitle>{t("rentalThreeStepsSection.sectionTitle")}</SectionTitle>
      <StepsContainer>
        {stepDataWithIcons.map((item, index) => (
          <StepContainer key={index}>
            <StepRight>
              <StepIcon src={item.icon} />
            </StepRight>
            <StepLeft>
              <StepTitle>{item.title}</StepTitle>
              <StepDivider />
              <StepDesc>{item.desc}</StepDesc>
            </StepLeft>
          </StepContainer>
        ))}
      </StepsContainer>
    </RentalThreeStepsContainer>
  );
};

export default RentalThreeStepsSection;
