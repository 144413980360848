import { Formik } from "formik";
import React, { useRef, useState } from "react";
import {
  CallbackContainer,
  CallbackFormContainer,
  CallbackFormItemContainer,
  CallbackIcon,
  CallbackSubTitle,
  CallbackTitle,
  CallbackTopContainer,
  CallbackTopLeftContainer,
  CallbackTopRightContainer,
  FormikContainer,
} from "./CallbackElements";
import emailjs from "@emailjs/browser";
import CallbackSuccessModal from "./CallbackSuccessModal";
import { Button, CircularProgress, TextField } from "@mui/material";
import callbackSectionIcon from "../../images/callbackSectionIcon.svg";
import { useTranslation } from "react-i18next";

const CallbackSection = () => {
  const { t, i18n } = useTranslation();
  const currentLanguageIsEN = i18n.language === 'en';
  const formRef = useRef();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validate = (values) => {
    const errors = {};

    if (!values.user_name) {
      errors.user_name = currentLanguageIsEN ? "This field is requred" : "Обязательно для заполнения";
    }

    if (!values.user_phone) {
      errors.user_phone = currentLanguageIsEN ? "This field is requred" : "Обязательно для заполнения";
    } else if (
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/i.test(values.user_phone)
    ) {
      errors.user_phone = currentLanguageIsEN ? "Invalid phone number" : "Неверный формат номера телефона";
    }

    if (!values.message) {
      errors.message = currentLanguageIsEN ? "This field is requred" : "Обязательно для заполнения";
    }

    return errors;
  };

  const sendEmail = async () => {
    await emailjs
      .sendForm("service_588o7nx", "template_y7siasn", formRef.current, {
        publicKey: "y02E3H2JNQ85FbDoB",
      })
      .then(
        () => {
          handleOpen();
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <CallbackSuccessModal open={open} handleClose={handleClose} />
      <CallbackContainer id="callback">
        <CallbackTopContainer>
          <CallbackTopLeftContainer>
            <CallbackTitle>{t("callbackSection.title")}</CallbackTitle>
            <CallbackSubTitle>
              {t("callbackSection.firstSubTitle")}
            </CallbackSubTitle>
            <CallbackSubTitle>{t("callbackSection.lastSubTitle")}</CallbackSubTitle>
          </CallbackTopLeftContainer>
          <CallbackTopRightContainer>
            <CallbackIcon src={callbackSectionIcon} />
          </CallbackTopRightContainer>
        </CallbackTopContainer>
        <FormikContainer>
          <Formik
            initialValues={{ user_name: "", user_phone: "", message: "" }}
            validate={validate}
            onSubmit={async (values, { setSubmitting }) => {
              await sendEmail();
              setSubmitting(false);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form ref={formRef} onSubmit={handleSubmit}>
                <CallbackFormContainer>
                  <CallbackFormItemContainer>
                    <TextField
                      InputProps={{
                        sx: {
                          borderRadius: "50px",
                        },
                      }}
                      name="user_name"
                      label={t("callbackSection.nameField")}
                      size="medium"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_name}
                      error={errors.user_name && touched.user_name}
                      helperText={
                        errors.user_name && touched.user_name
                          ? errors.user_name
                          : undefined
                      }
                      fullWidth
                    />
                  </CallbackFormItemContainer>
                  <CallbackFormItemContainer>
                    <TextField
                      fullWidth
                      InputProps={{
                        sx: {
                          borderRadius: "50px",
                        },
                      }}
                      name="user_phone"
                      label={t("callbackSection.phoneField")}
                      size="medium"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_phone}
                      error={errors.user_phone && touched.user_phone}
                      helperText={
                        errors.user_phone && touched.user_phone
                          ? errors.user_phone
                          : undefined
                      }
                    />
                  </CallbackFormItemContainer>
                  <CallbackFormItemContainer>
                    <TextField
                      fullWidth
                      InputProps={{
                        sx: {
                          borderRadius: "50px",
                        },
                      }}
                      label={t("callbackSection.questionField")}
                      size="medium"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      error={errors.message && touched.message}
                      helperText={
                        errors.message && touched.message
                          ? errors.message
                          : undefined
                      }
                      name="message"
                    />
                  </CallbackFormItemContainer>
                  <CallbackFormItemContainer>
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: "#4cbcd7",
                        borderRadius: 50,
                        height: 52,
                      }}
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      startIcon={
                        isSubmitting ? (
                          <div
                            style={{
                              width: "16px",
                              marginTop: "-16px",
                              height: "16px",
                              color: "white",
                            }}
                          >
                            <CircularProgress size="inherit" color="inherit" />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    >
                      {t("callbackSection.submitBtn")}
                    </Button>
                  </CallbackFormItemContainer>
                </CallbackFormContainer>
              </form>
            )}
          </Formik>
        </FormikContainer>
      </CallbackContainer>
    </>
  );
};

export default CallbackSection;
