import React from "react";
import { useTranslation } from "react-i18next";
import PrivacyPolicyEN from "./PrivacyPolicyEN";
import PrivacyPolicyRU from "./PrivacyPolicyRU";
import ContactsSection from "../../components/ContactsSection";
import CallbackSection from "../../components/CallbackSection";

const PrivacyPolicyPage = () => {
  const { i18n } = useTranslation();
  const currentLanguageIsEN = i18n.language === 'en';

  return (
    <>
      {currentLanguageIsEN ? <PrivacyPolicyEN /> : <PrivacyPolicyRU />}
      <CallbackSection />
      <ContactsSection />
    </>
  );
};

export default PrivacyPolicyPage;
