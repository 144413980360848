import styled from "styled-components";

export const SectionTitle = styled.h1`
  font-size: 25px;
  color: black;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const SecondPagesWrapper = styled.div`
  margin-top: 140px;
  max-width: 1440px;
  padding: 0 25px;
  margin: 100px auto 0;
`;
