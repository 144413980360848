import React from "react";
import {
  Footer,
  FooterBottom,
  FooterBottomLink,
  FooterNavbarContainer,
  FooterNavBtn,
  FooterNavItem,
  FooterNavLinks,
  FooterNavMenu,
  FooterNavPhone,
  FooterSectionContainer,
  FooterTop,
} from "./FooterSectionElements";
import { animateScroll as scroll } from "react-scroll";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { LogoIcon, NavLogo } from "../NavBar/NavbarElements";
import logoIcon from "../../images/logo.svg"

import { Button } from "../ButtonElement";
import { mainData } from "../../data/mainData";
import { useTranslation } from "react-i18next";

const FooterSection = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isSecondPage = location.pathname !== "/";
  const menuData = t("menuData", { returnObjects: true }) ?? [];

  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80, // Adjust offset as needed
        behavior: "smooth",
      });
    }
  };

  const handleClick = (id) => {
    if (isSecondPage) {
      history.push("/");
      setTimeout(() => {
        scrollToId(id);
      }, 300);
    } else {
      scrollToId(id);
    }
  };

  return (
    <Footer>
      <FooterSectionContainer>
        <FooterTop>
          <FooterNavbarContainer>
            <FooterNavMenu>
              <FooterNavItem>
                <NavLogo to="/">
                  <LogoIcon src={logoIcon} />
                  AMAIS
                </NavLogo>
                {menuData.map((item, index) => (
                  <FooterNavLinks
                    onClick={() => handleClick(item.link)}
                    key={index}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    {item.title}
                  </FooterNavLinks>
                ))}
              </FooterNavItem>
            </FooterNavMenu>
            <FooterNavBtn>
              <FooterNavPhone href={`tel:${mainData.phoneNumber}`}>
                {mainData.phoneNumber}
              </FooterNavPhone>
              <Button
                small
                to={"callback"}
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-120}
                dark="true"
              >
                {t("callbackBtn")}
              </Button>
            </FooterNavBtn>
          </FooterNavbarContainer>
        </FooterTop>
        <FooterBottom>
          <FooterBottomLink
            onClick={() => {
              history.push("/terms-and-conditions");
              scroll.scrollToTop();
            }}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            {t("footerSection.termsConditions")}
          </FooterBottomLink>
          <FooterBottomLink
            onClick={() => {
              history.push("/privacy-policy");
              scroll.scrollToTop();
            }}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            {t("footerSection.privacyPolicy")}
          </FooterBottomLink>
        </FooterBottom>
      </FooterSectionContainer>
    </Footer>
  );
};

export default FooterSection;
