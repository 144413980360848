import styled, { keyframes } from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 25px;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1440px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-10%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;
`;

export const NavLinks = styled(LinkS)`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #4cbcd7;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #4cbcd7;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 0.5rem;
  }
`;

export const NavPhone = styled.a`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  margin-right: 20px;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #4cbcd7;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const MobileNavLinkWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;
  }
`;

export const LogoIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const NavLInkIcon = styled.img`
  height: 29px;
  width: 29px;
  margin-right: 10px;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    animation: ${shake} 0.6s;
  }
`;

export const NavbarCallbackView = styled.div`
  display: block;

  @media screen and (max-width: 1300px) {
    display: none;
  }
`;
