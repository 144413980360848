import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import plusIcon from "../../../images/plus.svg";
import arrowLeftIcon from "../../../images/arrow-left-circle.svg";
import arrowRightIcon from "../../../images/arrow-right-circle.svg";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { useSwipeable } from "react-swipeable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  width: "100%",
  height: "100%",
  border: "none",
};

const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SliderLeftButton = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 50%;
  left: 0px;
  display: flex;
  z-index: 10;
`;

const SliderRightButton = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 50%;
  right: 0px;
  display: flex;
  z-index: 10;
`;

const DotsWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${({ active }) =>
    active ? "#4cbcd7" : "rgba(76, 188, 215, 0.5)"};
  transition: background-color 0.3s ease;
`;

const CloseButtonView = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  z-index: 10;
`;

const CloseButton = styled.img``;

const ImageCaruselModal = (props) => {
  const [current, setCurrent] = React.useState(0);
  const length = props.images.length;

  React.useEffect(() => {
    setCurrent(0);
  }, [props.images]);

  const nextSlide = () => {
    setCurrent((prev) => (prev === length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? length - 1 : prev - 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!Array.isArray(props.images) || props.images.length <= 0) {
    return null;
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} {...handlers}>
        <HeroWrapper>
          {props.images.map((image, index) => (
            <HeroSlide key={index} active={index === current}>
              {index === current && (
                <HeroSlider>
                  <HeroImage src={image} alt={`Slide ${index + 1}`} />
                </HeroSlider>
              )}
            </HeroSlide>
          ))}
          <CloseButtonView>
            <CloseButton src={plusIcon} onClick={props.handleClose} />
          </CloseButtonView>
          <SliderLeftButton>
            <IconButton color="primary" size="large" onClick={prevSlide}>
              <img src={arrowLeftIcon} alt="Previous" />
            </IconButton>
          </SliderLeftButton>
          <SliderRightButton>
            <IconButton color="primary" size="large" onClick={nextSlide}>
              <img src={arrowRightIcon} alt="Next" />
            </IconButton>
          </SliderRightButton>
          <DotsWrapper>
            {props.images.map((_, index) => (
              <Dot key={index} active={index === current} />
            ))}
          </DotsWrapper>
        </HeroWrapper>
      </Box>
    </Modal>
  );
};

export default ImageCaruselModal;
